export const translationsObject = {
    ru: {
        application: {
            title: 'Awesome app with i18n!',
            hello: 'Hello, %{name}!'
        },
        warningPaidDays0: "Истекает срок лицензионного договора. Обратитесь в ТОО \"Мединформ\": разработчик  8(727) 241-12-16,  фин.отдел 8(727) 241-13-07",
        warningPaidDays: "Истекает срок лицензионного договора. У вас доступ к программе будет еще %{days} дней. Обратитесь в ТОО \"Мединформ\": разработчик  8(727) 241-12-16,  фин.отдел 8(727) 241-13-07",
        date: {
            short: 'D.MM.YYYY',
            shorttime: 'D.MM.YYYY h:mm',
            long: 'D MMMM YYYY',
            longtime: 'D MMMM YYYY h:mm'
        },
        msgButtons: {
            OK: "OK",
            Yes: "Да",
            No: "Нет",
            Retry: "Повтор",
            Cancel: "Отмена",
            Request: "Заявка на открепления карты",
            Submit: "Сохранить изменения",
            Save: "Сохранить",
            Change: "Изменить",
            Load: "Загрузить"
        },
        lang: 'Язык',
        hello: 'Здравствуйте %{fio}',
        policy: "Политика конфиденциальности и обработки персональных данных",
        year3: "за %{year} год",
        yearMonth: "за %{month} %{year} года",
        home: 'Медстат, Мединфо',
        nameRu: 'Наименование (рус)',
        nameKz: 'Наименование (каз)',
        shortNameRu: 'Корорткое наименование (рус)',
        shortNameKz: 'Корорткое наименование (каз)',
        notinclude: "Исключить",
        orgIsNomenkl: "Организации с номенклатурой",
        deleteForm: "Удаление форм",
        rk: "Республика казахстан",
        blockRk: "Данные формы заблокированны на уровне РК",
        blockTableRk: "Данные таблицы заблокированны на уровне РК",
        blockTableObl: "Данные таблицы заблокированны на уровне области",
        btDeleteForm: "Удалить форму",
        deleteComplete: "Форма удалена",
        deleteYesOrNo: "Вы действительно хотите удалить данные формы?",
        indicatorsMap: {
            title: "Индикаторы на карте",
            run: "Сформировать"
        },
        genMedinfo: {
            yearsError1: 'Неправильное выражение',
            yearsError2: 'Количество годов больше 10',
            yearsError3: 'Год не должен быть меньше %{year}',
            yearsError4: 'Год не должен быть больше %{year}',
            forOrg: 'Выводить в разрезе организаций',
        },
        kod: "Код",
        nameOrgBr: "Наименование организации",
        login: {
            enter: 'Выполнить вход',
            comment1: 'Программа доступна, для пользователей, имеющих лицензионное соглашение',
            comment2: 'Если вы не являетесь авторизованным пользователем, отключитесь от системы.',
            comment3: 'Для заключения договора пакет документов скачать',
            comment4: 'здесь',
            licenseUrl: 'license',
            password: 'Пароль',
            username: 'Логин',
            passReq: 'Пароль обязательно',
            usrReq: 'Имя пользователя обязательно',
            userNamePassInvalid: 'Неправильное имя пользователя и пароль!',
            notPaid: 'Ваша организация не оплатила за использование программы',
            text1: 'Информационная система «МЕДСТАТ»',
            text2: 'Система формирования официальных статистических отчетных форм по деятельности медицинских организаций.',
            text3: 'Сравнительный анализ данных  внутри самой статистической  формы (внутриформенный контроль).',
            text4: 'Сопоставлять  данные между разными формами (межформенный контроль).',
            text5: 'Сопоставлять  данные между текущим и и предыдущим календарным годом (межгодовой контроль).',
            text6: 'Информационная система «МЕДИНФО»',
            text7: 'Система предназначена для сравнения и анализа медико-статистических показателей за различные периоды в виде таблиц, диаграмм и на карте в разрезе областей, районов и медицинских организаций.',
            text8: 'Показатели подразделяются на государственные, частные и ведомственные, также в разрезах городского и сельского здравоохранения.',
            text9: 'Система предназначена для организаторов здравоохранения и специалистов медицинских организаций.'
        },
        license: {
            text1: "Оформление лицензионного соглашения",
            text2: "Для заключения договора о лицензионном соглашении с ТОО “Мединформ” получателю необходимо:",
            text3: "Скачать, заполнить и подписать",
            text4: "договор о лицензионном соглашении",
            text5: "Скачать",
            text6: "авторское свидетельство",
            text7: "Оплатить стоимость Годовой Лицензии;",
            text8: "Отправить по электронной почте",
            text9: "электронные документы:",
            text10: "Копию договора о лицензионном соглашении;",
            text11: "Документ, подтверждающий оплату Годовой Лицензии(платежное поручение, банковская квитанция, чек или выписка из интернет-банкинга);",
            text12: 'Доступ к порталу "Медстат" будет предоставлен после оплаты Годовой Лицензии',
            text13: "Контактные телефоны:"
        },
        formul: {
            header: 'Редактирование формул контроля',
            selform: 'Выберите форму',
            loadFormuls: 'Загрузить формулы'
        },
        filter: "Фильтр",
        register: "Регистрация",
        wait: 'Подождите...',
        waitsvod: 'Подождите формирование свода...',
        waitexcel: "Подождите формирование файла Excel...",
        loading: 'Загрузка...',
        enter: 'Выполнить вход',
        entered: 'Вход в систему...',
        exit: 'Выход',
        adm: 'Управление',
        admins: 'Управление %{tadm}',
        adminUsers: 'пользователями',
        adminAdmins: 'администраторами',
        textAdminAdmins: 'Управление администраторами. Добавление/редактирование свойств администраторов',
        textAdminUsers: 'Управление пользователями. Добавление/редактирвание свойств пользователей',
        nsi: 'Справочники',
        yes: "Да",
        no: "Нет",
        version: "версия",
        version2: "Версия %{version}",
        versions: {
            tit: "Информация по версии",
            versionNo: "Номер",
            dtVersion: "Дата",
            comment: "Коментарий",
        },
        versionInfo:"Информация об изменениях в портале",
        bud: 'Бюджетная отчетность ГУ',
        fin: 'Учет оплаты за использование портала',
        finTitle: 'Оплата',
        finPrivateOrg: 'Частная организация',
        finGovOrg: 'Государственная организация',
        fin50persOrg: 'Оплата 50%',
        debetkred: 'Кредиторская и дебиторская задолженность',
        reports: 'Отчеты',
        year: 'Год',
        year2: 'год',
        prog: 'Программа',
        podprog: 'Подпрограмма',
        userOrg: 'Ввод данных осуществляет:',
        svod: "Получение сводов",
        svod2: "Свод",
        svodSub: "Свод подчиненых организаций",
        svodSpecificOrg: {
            tit: "Свод по конкретным организациям района",
            btGetListOrgs: "Пролучить список организаций, у которых есть данные",
            btGenSvodExcel: "Сгенерировать файл свода Excel по выбранным организациям",
        },
        svodFormOrgRaz: {
            tit: "Слияние разрезов форм по каждой организации",
            comment: "Сведение разрезов \"Город (свод)\" + \"Село (свод)\" в разрез \"Город+Село (свод)\" по каждой организации"
        },
        InputInforForm: {
            tit: "Информация о вводе по форме"
        },
        InputInforObj: {
            tit: "Информация о вводе по объекту"
        },
        svodSubText: "Будет произведен свод по подчиненым организациям в разрезе:\"%{srcRaz}\" и помещен в разрез:\"%{targetRaz}\" головной организации:\"%{org}\"",
        svodComplete: "Свод сформирован!",
        copyDataComplete: "Данные скопированы!",
        object: "Объект",
        openForm: "Открытие форм/таблиц",
        openFormPstr: "Построчка",
        svodForm: "Сведение форм",
        svodFormQuart: "Сведение форм по кварталам",
        svodFormMonths: "Сведение форм по выбранным месяцам",
        svodFormRaz: "Слияние форм по разрезам",
        razChanged: "Разрез изменен!",
        periodChanged: "Период изменен!",
        execSvod: "Получить свод",
        copyData: "Скопировать данные",
        msgCopyData: "Копирование данных",
        textCopyData: "Предупеждение: Перед копированием данных выбранного раздела и выбраных форм <strong>месячного отчета за декабрь %{year} года</strong> в <strong>годовой отчет %{year} года</strong>, данные выбранного раздела и выбраных форм в <strong>годовом отчете %{year} года</strong> будут удалены!",
        textCopyData2: "Выполнить копирование данных?",
        copy12MonthYoYear: "Копирование данных с декабря месячного отчета в годовой отчет",
        copy12MonthYoYearComment: "В результате выполнения данной операции данные <strong>месячного отчета за декабрь %{year} года</strong> будут скопированы в <strong>годовой отчет %{year} года</strong>",
        control: "Контроль",
        controlInForm: "Внутриформенный",
        controlAllForm: "Межформенный",
        controlInYear: "Межгодовой",
        work: "Работа",
        forma: "Форма",
        razdelForm: "Раздел форм",
        allForms: "Все формы",
        filtrForma: "Фильтр по Форме:",
        filtrRaz: "Фильтр по Разрезу:",
        table: "Таблица",
        pstr: "Построчка",
        period: "Период",
        razres: "Разрез",
        typeobj: "Тип объекта",
        obj: "Объект",
        obj_no_select: "Объект не выбран!",
        obl: "Область",
        select: "Выбрать",
        selectObls: "Выбор областей",
        selectOrg: "Выберите организацию",
        selectOrgs: "Выбор организаций",
        selectOrgs2: "Выберите организации",
        selectAllObls: "Выбрать все области",
        selectForm: "Выберите форму",
        raion: "Район",
        allRaions: "все районы",
        selectRaions: "Выбор районов",
        selectAllRaions: "Выбрать все районы",
        selectAllOrgs: "Выбрать все организации",
        org: "Организация",
        headOrg: "Головная организация",
        headOrg2: "Головную организацию",
        selHeadOrg: "Выберите головную организацию",
        onlyNomen: "Включить только организации с номенклатурой",
        viewData: "Вид",
        editcontrol: "Редактирование формул контроля",
        toExcel: "Генерация Excel форм",
        toExcel2: "Генерация Excel формы",
        fromExcel: "Загрузка данных из Excel файла",
        fromExcel2: "Загрузка данных из Excel файла...",
        resultFromExcel2Ok: "Загрузка данных из Excel файла %{fileExcel} произведена успешно!",
        selectExcelFile: "Выберите файл Excel",
        fileExcel: "Файл с данными формы Excel",
        inputInfor: "Информация о вводе по разрезу",
        noerror: "Ошибок нет!",
        error: "Ошибка:",
        generateExcel: "Генерация Excel формы...",
        save: "Сохранить",
        instruction: "Руководство пользователя",
        period1: "1-й квартал",
        period2: "1-ое полугодие",
        period3: "9 месяцев",
        period4: "за год",
        period10:"1-й квартал",
        period20:"2-й квартал",
        period21:"1-ое полугодие",
        period30:"3-й квартал",
        period31:"9 месяцев",
        period40:"4-й квартал",
        period101: "Январь",
        period102: "Февраль",
        period103: "Март",
        period104: "Апрель",
        period105: "Май",
        period106: "Июнь",
        period107: "Июль",
        period108: "Август",
        period109: "Сентябрь",
        period110: "Октябрь",
        period111: "Ноябрь",
        period112: "Декабрь",
        dataSaved: "Данные сохранены!",
        saving: "Сохранение...",
        closingPeriod: "Закрытие периода",
        closingOrgPeriod: "Закрытие периода для организаций",
        create: "Создать",
        loadDataFromExcel: "Загрузить данные из файла Excel",
        reportfin: "Отчет об оплате",
        reportfincrrz: "Отчет о договорах организаций",
        editOrgsOnObl: "Редактирование справочника организаций",
        summaDog: "Сумма по договору",
        dogovor: "Договор",
        str: "Строка",
        strItog: "Итого по строке",
        strocs: "Строки",
        raions: "Районы",
        orgs: "Организации",
        orgsraions: "Организации района",
        svodRaz: "Получение сводов по разрезам",
        razSource: "Разрезы источника",
        razSourceOne: "Разрез источника",
        razDest: "Итоговый разрез",
        createSvod: "Получить свод",
        createSvodRaz: "Обьединить разрезы",
        warning: "Предупреждение",
        razSvodConfirmMsg: "В базе имеются данные в итоговом разрезе. Перед созданием свода данные итогового разреза будут удалены!",
        razEditConfirmMsg: "В базе имеются данные в итоговом разрезе. Перед переносом данных, данные итогового разреза будут удалены!",
        perEditConfirmMsg: "В базе имеются данные в итоговом периоде. Перед переносом данных, данные итогового периода будут удалены!",
        svodRazBtCreate: "Создать свод",
        cancel: "Отмена",
        svodRazBtCancel: "Не создавать свод",
        editRazBtCreate: "Перенести данные",
        editRazBtCancel: "Не переносить данные",
        isAfterSvodDeleteSrc: "Удалить данные разреза источника после свода(перноса) в итоговой разрез",
        editFormRaz: "Редактирование разреза",
        editFormOrgRaz: "Редактирование разреза по каждой организации",
        editFormPeriod: "Редактирование периода",
        changeRazrez: "Изменить разрез",
        changePeriod:"Изменить период",
        nomenkl: "Номенклатура",
        typeorg: "Тип организации",
        gen47: "Генерация 47 формы",
        gen47Param: "Параметры организаций для генерации 47 формы",
        gen47Instr: "Инструкция по генерации 47 формы",
        medinfoTitle: "Мединфо",
        medinfoComment: "Для работы с Мединфо данные по населению должны быть внесены в разрез Свод Здравоохранения. Данные Медстата должны быть сведены в разрезы Город + Село, МЗ+Ведомства.",
        editFormulMedinfo: "Редактирование формул \"Мединфо\"",
        generateTableMedinfo: "Медико-статистические показатели",
        page: "Страница",
        type_table: "Тип таблицы",
        type_per: "Тип периода",
        years: "год",
        quarter:"квартал",
        quarters: "кварталы (все)",
        quartersone: "квартал (по выбору)",
        months: "месяц",
        months2:"Месяцы",
        yearBeg: "Первый год",
        yearEnd: "Второй год",
        errorYears1: "Первый год не должен быть равен или больше чем второй",
        errorYears2: "Количество лет не должно быть больше 10",
        btGenMedinfoTable: "Сгенерировать таблицу",
        createDiagram: "Создать диаграмму",
        type_diagram: "Тип диаграммы",
        diagrGist: "Гистограмма",
        diagrGraph: "График",
        diagrCircle: "Круговая диаграмма",
        diagrline: "Линейчатая диаграмма",
        diagrMap: "Карта",
        editBokMkbMedinfo: "Справочник боковиков для 2 генерации (МКБ)",
        editBokDrMedinfo: "Справочник боковиков для 2 генерации (другие)",
        yearsExprStrings: "Годы (пример - 2017-2018,2021)",
        editPartsMedinfo: "Редактирование разделов Мединфо",
        part: "Раздел",
        indicator: "Показатель",
        showWarningDiagram: "При выборе периода более трех лет данные на диаграмме могут сливаться. Желательно выбирать период  меньше трех лет.",
        openformtab: "Открытие формы и таблицы",
        pokazateli: "Показатели",
        kodstr: "Код строки(код контроля)",
        recalcItogs: "Расчитать итоги",
        edit: "Редактировать",
        delete: "Удалить",
        print: "Печать",
        deletedData: "Данные удалены!",
        deleteDataForm: "Удаление данных таблицы",
        deleteDataForm2: "Удаление данных формы",
        deleteQuestion: "Удалить данные таблицы: %{table}, формы: %{form}, код обьекта: %{kobj}?",
        deleteQuestion2: "Удалить данные, формы: %{form}, обьект: %{obj}, год:%{year}, период:%{period}, разрез:%{raz}?",
        noDataForm: "Нет данных формы",
        close: "Закрыть",
        accept: "Принять",
        search: "Поиск",
        exportToExcel: "Экспорт в Excel",
        object: "Обьект",
        selectParams: "Выбор параметров",
        selectedParams: "Выбранные параметры",
        load: "Загрузить",
        hide: "Скрыть",
        yearClose: "Год для редактирования годового отчета",
        yearCloseMonth: "Год для редактирования месячного отчета",
        monthClose: "Месяц редактирования месячного отчета",
        showRequiredSave: "По данной организации нет данных в базе данных, необходимо сохранить данные",
        childOrgs: {
            title: "Подчиненые организации",
            parentOrg: "Головная организация",
            kodOrg: "Код",
            nameOrg: "Наименование",
            del: "Убрать подчинение",
            addChild: "Добавить дочернию организацию",
            addSucces: "Дочерняя организация добавлена!",
            removeSucces: "Подчинение убрано!",
            addNoOrgFail: "Нет организации",
            warnOrgIsExistParent: "У данной организации есть головная организация: %{kodOrg}",
            addOrgToOkpolu: "Добавить организацию в okpolu",
            childOrg: "Подчиненная организация",
            users: "Пользователи подчиненых организаций",
            users2: "Пользователи",
            editNameOrg: "Изменение наименования организации",
        },
        forRaion: "По району",
        forSub: "По ЦРБ и подчиненным организациям",
        selectForms: "Выберите формы",
        selectMonths: "Выберите месяцы",
        razSrcEqDest: "Разрез источника не должен быть равен итоговому разрезу",
        selectObjs: "Выберите обьекты",
        selectRazSrc: "Выберите разрезы источника",
        statusForm: {
            title: "Статус форм (блокировка)",
            tit2: "Организации и статусы форм",
            titFormUncheck: "- Форма не проверена(изменение данных организацией разрешено)",
            titFormCheck: "- Форма проверена(изменение данных организацией запрещено)",
            titFormUncheckRk: "- Форма не проверена(изменение данных на ниже лежащих уровнях(области/организации) разрешено)",
            titFormCheckRk: "- Форма проверена(изменение данных на ниже лежащих уровнях(области/организации) запрещено)",
        },
        statusTable: {
            title: "Статус таблиц (блокировка)"
        },
        checked: "Проверено",
        noChecked: "Не проверено",
        infoData: {
            print: "Печать",
            printExcel: "Сформировать файл Excel",
            title: "Состояние наличия данных",
            period: "Период:",
            raz: "В разрезе:",
            form: "Форма:",
            obj: "Объект:",
            dt: "Дата и время создания:",
        },
        orgSubordinates: "Подчиненые организации",
        all: "Все",
        selectOrgDlg: {
            title: "Выберите организацию",
            comment: "Для поиска организаций введите часть кода или наименования организации"
        },
        packetDoc: {
            t1: "Оформление лицензионного соглашения",
            t2: "Для заключения договора о лицензионном соглашении с ТОО “Мединформ” необходимо",
            t3: "Скачать, заполнить и подписать",
            t4: "договор о лицензионном соглашении",
            t5: "Скачать",
            t6: "авторское свидетельство",
            t7: "Оплатить стоимость Годовой Лицензии",
            t8: "Отправить по электронной почте",
            t9: "электронные документы",
            t10: "Копию подписанного договора о лицензионном соглашении",
            t11: "Документ, подтверждающий оплату Годовой Лицензии(платежное поручение, банковская квитанция, чек или выписка из интернет-банкинга)",
            t12: "Для заключения договора на портале Государственных закупок",
            t13: "Техническая спецификация",
            t14: "Коммерческое предложение",
            t15: "КТРУ 711211.000.000001 услуги консультационные технические",
            t16: "Доступ к порталу \"Медстат\" будет предоставлен после оплаты Годовой Лицензии",
            t17: "Контактные телефоны",
            t18: "Вход в портал",
            t19: "Скачать инструкцию по работе в портале \"Медстат\", \"Мединфо\"",
            t150: "Номер объявления отправить на электронную почту",
            t151: "Хабарландырудың номерін",
            t152: "электрондық поштаға жіберу керек.",
        },
        editOrgs: {
            kod: "Код",
            nameRu: "Наименование (рус)",
            nameKz: "Наименование (каз)",
            te: "Территориальная единица (te)",
            obl: "Область (obl)",
            raion: "Район (raion)",
            nasel: "Населенный пункт, территориальная единица (te)",
            adr: "Адрес",
            isMedstat: "Организации разрешен ввод данных в Медстат",
            edit: "Редактирование",
            loading: "Загрузка...",
            filt: "Фильтр",
            kodName: "Код/Наименование",
            notRaion: "Не установлен район",
            clearFilter: "Очистить фильтр",
        },
        accessRazd: {
            tit: "Разрешения ввода по разрезам/формам",
            titIsAccess: "Разрезы/формы для ввода данных организацией:",
            allRf: "Все",
            listRf: "Списком",
            razrs: "Разрезы",
            forms: "Формы",
            selRazs: "Выберите разрезы",
            selForms: "Выберите формы",
            createList: "Создать список форм и разрезов на основе имеющихся данных данных за отчетный период:",
            btCopyFromYear: "Создать список",
            isGod: "Годовой отчет",
            isMes: "Месячный отчет"
        },
        noAccess: "Нет доступа",
        audit: {
            tit: "Аудит действий пользователей",
            startDate: "Период с",
            endDate: "по",
            action: "Действие пользователя",
            actionDelete: "Удаление форм/таблиц",
            logged: "Дата",
            action: "Действие",
            message: "Сообщение",
            user: "Пользователь",
            ipAddress: "IP адрес"
        },
        classMkb: "Класс МКБ-10",
        templateExcel: {
            title: "Шаблоны форм Excel",
            load: "Загрузить",
        },
        quartsComments:{
            q10: "= январь+февраль+март",
            q20: "= апрель+май+июнь",
            q21: "= январь+февраль+март+апрель+май+июнь",
            q30: "= июль+август+сентябрь",
            q31: "= январь+февраль+март+апрель+май+июнь+июль+август+сентябрь",
            q40: "= октябрь+ноябрь+декабрь",
            q4: "= январь+февраль+март+апрель+май+июнь+июль+август+сентябрь+октябрь+ноябрь+декабрь",
            q400: "= 1-квартал + 2-квартал + 3-квартал + 4-квартал",
            q210: "= 1-квартал + 2-квартал",
            q310: "= 1-квартал + 2-квартал + 3-квартал",
            svodSrc: "Сводить:",
            error:"Ошибка: %{err}"

        },
        perSrcEqDest: "Период источника не должен быть равен итоговому периоду",
        periodSrc: "Период источника:",
        periodDest: "Итоговый период:",
        notExistsDataSrcPer: "Нет данных в источнике по данноому периоду",
        pstrCntObj: "Объектов %{cnt}",
        erMonthQuart:"Данных по месяцам/кварталам за данный период/периоды нет"
    },
    kk: {
        application: {
            title: 'Toffe app met i18n!',
            hello: 'Hallo, %{name}!'
        },
        warningPaidDays0: "Лицензиялық келісімнің мерзімі аяқталады. «Мединформ» ЖШС байланысы: әзірлеуші 8(727) 241-12-16, қаржы бөлімі 8(727) 241-13-07",
        warningPaidDays: "Лицензиялық келісімнің мерзімі аяқталады. Бағдарламаға тағы %{days} күн қол жеткізе аласыз. «Мединформ» ЖШС байланысы: әзірлеуші 8(727) 241-12-16, қаржы бөлімі 8(727) 241-13-07",
        date: {
            short: 'D.MM.YYYY',
            shorttime: 'D.MM.YYYY h:mm',
            long: 'D MMMM YYYY',
            longtime: 'D MMMM YYYY h:mm'
        },
        msgButtons: {
            OK: "OK",
            Yes: "Иа",
            No: "Жоқ",
            Retry: "Қайталау",
            Cancel: "Болдырмау",
            Request: "Картаны тіркеуден шығаруға өтінім",
            Submit: "Деректерді сақтау",
            Save: "Сақтау",
            Change: "Өзгерту",
        },
        lang: 'Тіл',
        hello: 'Сәлеметсіз бе %{fio}',
        policy: "Құпиялылық және жеке деректерді өңдеу саясаты",
        year3: "%{year} жылға",
        yearMonth: "%{year} жылдың %{month}",
        home: 'Медстат, Мединфо',
        nameRu: 'Атауы (рус)',
        nameKz: 'Атауы (каз)',
        shortNameRu: 'Қысқа аты (орыс)',
        shortNameKz: 'Қысқа атау (қаз)',
        notinclude: "Алып тастау",
        orgIsNomenkl: "Номенклатурасы бар ұйымдар",
        deleteForm: "Пішіндерді жою",
        rk: "Қазақстан Республикасы",
        blockRk: "Бұл формалар ҚР деңгейінде бұғатталған",
        blockTableRk: "Кесте деректері ҚР деңгейінде бұғатталған",
        blockTableObl: "Кесте деректері аймақ деңгейінде құлыпталған",
        btDeleteForm: "Пішінді жою",
        deleteComplete: "Пішін жойылды",
        deleteYesOrNo: "Пішін деректерін шынымен жойғыңыз келе ме?",
        indicatorsMap: {
            title: "Картадағы көрсеткіштер",
            run: "Пішін"
        },
        genMedinfo: {
            yearsError1: 'Жарамсыз өрнек',
            yearsError2: 'Жылдар саны 10-нан асады',
            yearsError3: '%{year} - дан кем болмауы керек',
            yearsError4: '%{year} - дан аспауы керек',
            forOrg: 'Ұйымдар контекстінде көрсету',
        },
        kod: "Код",
        nameOrgBr: "Ұйым атауы",
        login: {
            enter: 'Кіру',
            comment1: 'Бағдарлама бар пайдаланушылар үшін қолжетімді',
            comment2: 'Егер сіз авторизацияланған пайдаланушы болмасаңыз, жүйеден ажыратыңыз.',
            comment3: 'Шарт жасасу үшін құжаттар пакетін жүктеп алуға болады',
            comment4: 'Мына жерде',
            licenseUrl: 'license',
            password: 'Құпия сөз',
            username: 'Логин',
            passReq: 'Құпия сөз қажет',
            usrReq: 'Пайдаланушы аты қажет',
            userNamePassInvalid: 'Пайдаланушы аты мен пароль дұрыс емес!',
            notPaid: 'Сіздің ұйым бағдарламаны пайдаланғаны үшін төлем жасады',
            text1: '«МЕДСТАТ» ақпараттық жүйесі',
            text2: 'Медициналық ұйымдардың қызметі туралы ресми статистикалық есептілік нысандарын қалыптастыру жүйесі.',
            text3: 'Статистикалық нысанның ішіндегі деректерді салыстырмалы талдау (ішілік бақылау).',
            text4: 'Мәліметтерді әртүрлі формалармен салыстырыңыз (интерформ бақылау).',
            text5: 'Ағымдағы және өткен күнтізбелік жыл арасындағы деректерді салыстырыңыз (жылдық бақылау).',
            text6: '«МЕДИНФО» ақпараттық жүйесі',
            text7: 'Жүйе әртүрлі кезеңдердегі медициналық-статистикалық көрсеткіштерді кестелер, диаграммалар түрінде және облыстардың, аудандардың және медициналық ұйымдардың жағдайында картада салыстыруға және талдауға арналған.',
            text8: 'Көрсеткіштер мемлекеттік, жеке және ведомстволық, сонымен қатар қалалық және ауылдық денсаулық сақтау тұрғысынан бөлінеді.',
            text9: 'Жүйе денсаулық сақтауды ұйымдастырушылар мен денсаулық сақтау мамандарына арналған.'
        },
        license: {
            text1: "Оформление лицензионного соглашения",
            text2: "Для заключения договора о лицензионном соглашении с ТОО “Мединформ” получателю необходимо:",
            text3: "Скачать, заполнить и подписать",
            text4: "договор о лицензионном соглашении",
            text5: "Скачать",
            text6: "авторское свидетельство",
            text7: "Оплатить стоимость Годовой Лицензии;",
            text8: "Отправить по электронной почте",
            text9: "электронные документы:",
            text10: "Копию договора о лицензионном соглашении;",
            text11: "Документ, подтверждающий оплату Годовой Лицензии(платежное поручение, банковская квитанция, чек или выписка из интернет-банкинга);",
            text12: 'Доступ к порталу "Медстат" будет предоставлен после оплаты Годовой Лицензии',
            text13: "Контактные телефоны:"
        },
        formul: {
            header: 'Басқару формулаларын өңдеу',
            selform: 'Пішінді таңдаңыз',
            loadFormuls: 'Формулаларды жүктеңіз'
        },
        filter: "Сүзгі",
        register: "Тіркеу",
        wait: 'Күтіңіз...',
        waitsvod: 'Қойма түзілуін күтіңіз...',
        waitexcel: "Excel файлы жасалғанша күтіңіз...",
        loading: 'Жүктеу...',
        enter: 'Кіру',
        entered: 'Кіруге ...',
        exit: 'Шығу',
        adm: 'Бақылау',
        admins: '%{tadm} басқару',
        adminUsers: 'Пайдаланушыларды',
        adminAdmins: 'Әкімшілерді',
        textAdminAdmins: 'Әкімшілік басқару. Әкімші қасиеттерін қосу / өңдеу',
        textAdminUsers: 'Пайдаланушыларды басқару. Пайдаланушы сипаттарын қосу / өңдеу',
        nsi: 'Анықтамалар',
        yes: "Ия",
        no: "Жоқ",
        version: "нұсқасы",
        version2: "Нұсқасы %{version}",
        versions: {
            tit: "Нұсқа туралы ақпарат",
            versionNo: "Сан",
            dtVersion: "күні",
            comment: "Түсініктеме"
        },
        versionInfo: "Порталдағы өзгерістер туралы ақпарат",
        bud: 'Бюджетная отчетность ГУ',
        fin: 'Порталды пайдаланғаны үшін төлемдерді есепке алу',
        finTitle: 'Төлем',
        finPrivateOrg: 'Жеке ұйым',
        finGovOrg: 'Мемлекеттік ұйым',
        fin50persOrg: 'Төлем 50%',
        debetkred: 'Берешек және дебиторлық берешек',
        reports: 'Есептер',
        year: 'Жыл',
        year2:'жыл',
        prog: 'Бағдарлама',
        podprog: 'Подпрограмма',
        userOrg: 'Деректерді енгізу:',
        svod: "Қоймалар алу",
        svod2: "Қойма",
        svodSub: "Ведомстволық бағынысты ұйымдардың органы",
        svodSpecificOrg: {
            tit: "Аудандағы нақты ұйымдардың қысқаша мазмұны",
            btGetListOrgs: "Деректері бар ұйымдардың тізімін алыңыз",
            btGenSvodExcel:"Таңдалған ұйымдар үшін Excel жиынтық файлын жасаңыз",
        },
        svodFormOrgRaz: {
            tit: "Әрбір ұйым үшін пішін бөлімдерін біріктіру",
            comment: "\"Қала (арқа)\" + \"Ауыл (арка)\" бөлімдерін әрбір ұйым үшін \"Қала+Ауыл (арка)\" бөліміне біріктіру"
        },
        InputInforForm: {
            tit:"Пішін енгізу ақпараты"
        },
        InputInforObj: {
            tit: "Объектіні енгізу ақпараты"
        },
        svodSubText: "Ведомстволық бағынысты ұйымдардың қысқаша мазмұны:\"%{srcRaz}\" контекстінде жасалады және:\"%{org}\" негізгі ұйымының: \"%{targetRaz}\" бөлімінде орналастырылады.",
        svodComplete: "Қойма құрылды!",
        copyDataComplete:"Деректер көшірілді!",
        object: "Нысан",
        svodFormRaz: "Пішіндерді кесу арқылы біріктіру",
        razChanged: "Кесу өзгертілді!",
        periodChanged: "Мерзімі өзгертілді!",
        execSvod: "Қойма алыңыз",
        copyData:"Деректерді көшіру",
        msgCopyData:"",
        textCopyData:"Ескерту: Таңдалған бөлімнің деректерін және <strong>жылдың %{жыл} желтоқсанына арналған айлық есептің</strong> таңдалған пішіндерін <strong>%{year} жылдың жылдық есебіне</strong> көшірмес бұрын. strong>, таңдалған бөлімнің деректері және < strong>жылдық есептің %{year} жыл</strong>ына таңдалған пішіндер жойылады!",
        textCopyData2: "Деректерді көшіру керек пе?",
        copy12MonthYoYear:"Деректерді желтоқсан айындағы есеп беруден жылдық есепке көшіру",
        copy12MonthYoYearComment:"Осы операцияның нәтижесінде <strong>желтоқсан айының %{year}</strong> айлық есебі <strong>жылдық %{year}</strong> есебіне көшіріледі.",
        openForm: "Пішіндерді / кестелерді ашу",
        openFormPstr: "Түзу",
        svodForm: "Тегістеу формалары",
        svodFormQuart: "Тоқсан бойынша формаларды топтастыру",
        svodFormMonths: "Таңдалған айлар бойынша пішіндерді жинақтау",
        svodFormrRaz: "Пішіндерді кесу арқылы біріктіру",
        control: "Бақылау",
        controlInForm: "Ішкі",
        controlAllForm: "Интерфейс",
        controlInYear: "Жылдық",
        work: "Жұмыс",
        forma: "Пішін",
        allForms: "Барлық пішіндер",
        razdelForm: "Пішіндер бөлімі",
        filtrForma: "Пішін бойынша сүзу:",
        filtrRaz: "Бөлім бойынша сүзгілеу:",
        table: "Кесте",
        pstr: "Түзу",
        period: "Кезең",
        razres: "Қиық",
        typeobj: "Нысан түрі",
        obj: "Нысан",
        obj_no_select: "Нысан таңдалмаған!",
        obl: "Аймақ",
        select: "Таңдау",
        selectObls: "Аймақтар таңдау",
        selectOrg: "Ұйымды таңдаңыз",
        selectOrgs: "Ұйымдарды таңдау",
        selectOrgs2: "Ұйымдарды таңдаңыз",
        selectAllObls: "Барлық аймақтарды таңдаңыз",
        selectForm: "Пішінді таңдаңыз",
        raion: "Аудан",
        allRaions: "барлық аймақтар",
        selectRaions: "Аудандарды таңдау",
        selectAllRaions: "Барлық аймақтарды таңдаңыз",
        selectAllOrgs: "Барлық ұйымдарды таңдаңыз",
        org: "Ұйым",
        headOrg: "Бас ұйым",
        headOrg2: "Ата-ана ұйымы",
        selHeadOrg: "Негізгі ұйымды таңдаңыз",
        onlyNomen: "Тек номенклатурасы бар ұйымдарды қосыңыз",
        viewData: "Көру",
        editcontrol: "Басқару формулаларын редакциялау",
        toExcel: "Excel формаларын құру",
        toExcel2: "Excel пішіндерін жасау",
        fromExcel: "Excel файлынан деректерді жүктеу",
        fromExcel2: "Excel файлынан деректерді жүктеу...",
        resultFromExcel2Ok: "%{fileExcel} Excel файлынан деректерді жүктеу сәтті өтті!",
        selectExcelFile: "Excel файлын таңдаңыз",
        fileExcel: "Excel пішіміндегі мәліметтер файлы",
        inputInfor: "Бөлім енгізу ақпараты",
        noerror: "Қателер жоқ!",
        error: "Қате:",
        generateExcel: "Excel формасын құру...",
        save: "Сохранить",
        instruction: "Пайдаланушы нұсқаулығы",
        period1: "1 тоқсан",
        period2: "Жылдың 1 жартысы",
        period3: "9 ай",
        period4: "за год",
        period10: "1 тоқсан",
        period20: "2 тоқсан",
        period21: "Жылдың 1 жартысы",
        period30: "3 тоқсан",
        period31: "9 ай",
        period40: "4 тоқсан",
        period101: "Қаңтар",
        period102: "Ақпан",
        period103: "Наурыз",
        period104: "Сәуір",
        period105: "Мамыр",
        period106: "Маусым",
        period107: "Шілде",
        period108: "Тамыз",
        period109: "Қыркүйек",
        period110: "Қазан",
        period111: "Қараша",
        period112: "Желтоқсан",
        dataSaved: "Деректер сақталды!",
        saving: "Сохранение...",
        closingPeriod: "Мерзімнің аяқталуы",
        closingOrgPeriod: "Ұйымдар үшін кезеңнің аяқталуы",
        create: "А жасау",
        loadDataFromExcel: "Excel файлынан деректерді жүктеңіз",
        reportfin: "Төлем туралы есеп",
        reportfincrrz: "Ұйымдық келісімдер туралы есеп",
        editOrgsOnObl: "Ұйымдар анықтамалығын өңдеу",
        summaDog: "Келісімшарт сомасы",
        dogovor: "Келісімшарт",
        str: "Түзу",
        strItog:"Жалпы сызық",
        strocs: "Жолдар",
        raions: "Аймақтар",
        orgs: "Ұйым",
        orgsraions: "Аудандық ұйымдар",
        svodRaz: "Қоймаларды секциялар бойынша алу",
        razSource: "Бастапқы бөлімдер",
        razSourceOne: "Ақпарат көзі",
        razDest: "Соңғы кесу",
        createSvod: "Сақтау қоймасын алыңыз",
        createSvodRaz: "Кесулерді біріктіру",
        warning: "Ескерту",
        razSvodConfirmMsg: "Деректер қорында қорытынды бөлімдегі мәліметтер бар. Қойма жасамас бұрын жиынтық бөлімнің деректері жойылады!",
        razEditConfirmMsg: "Деректер қорында қорытынды бөлімдегі мәліметтер бар. Деректер берілмес бұрын, қорытынды бөлімнің деректері жойылады!",
        perEditConfirmMsg: "Мәліметтер қорында соңғы кезеңдегі деректер бар. Деректерді тасымалдау алдында соңғы кезең деректері жойылады!",
        svodRazBtCreate: "Қойма жаса",
        cancel: "Болдырмау",
        svodRazBtCancel: "Қойма жасамаңыз",
        editRazBtCreate: "Деректерді тасымалдау",
        editRazBtCancel: "Деректерді тасымалдамаңыз",
        isAfterSvodDeleteSrc: "Қойылғаннан кейін бастапқы бөлім деректерін соңғы бөлімге дейін алып тастаңыз (алып жүріңіз)",
        editFormRaz: "Бөлімді өңдеу",
        editFormOrgRaz: "Әрбір ұйым үшін бөлімді өңдеу",
        editFormPeriod: "Кезеңді өңдеу",
        changeRazrez: "Бөлімді өзгерту",
        changePeriod: "Кезеңді өзгерту",
        nomenkl: "Номенклатура",
        typeorg: "Ұйым түрі",
        gen47: "47 пішінді қалыптастыру",
        gen47Param: "47 форманы қалыптастырудың ұйымдастырушылық параметрлері",
        gen47Instr: "47 пішінді құру бойынша нұсқаулар",
        medinfoTitle: "Мединфо",
        medinfoComment: "Meдинфо-мен жұмыс істеу үшін денсаулық кодексінің бөлімінде халық туралы деректер енгізілуі керек. Медстат деректері Қалалық + Ауылдық, Денсаулық сақтау министрлігі + Департаменттер бөлімдерінде жинақталуы керек.",
        editFormulMedinfo: "\"Medinfo\" формулаларын өңдеу",
        generateTableMedinfo: "Медициналық-статистикалық көрсеткіштер",
        page: "Бет",
        type_table: "Кесте түрі",
        type_per: "Кезең түрі",
        years: "жыл",
        quarter: "тоқсан",
        quarters: "аудандар (барлығы)",
        quartersone: "тоқсан (міндетті емес)",
        months: "ай",
        months2: "Айлар",
        yearBeg: "Бірінші жыл",
        yearEnd: "Екінші жыл",
        errorYears1: "Бірінші жыл екінші жылға тең немесе одан үлкен болмауы керек",
        errorYears2: "Жылдар саны 10-нан аспауы керек",
        btGenMedinfoTable: "Кесте құру",
        createDiagram: "Диаграмма құру",
        type_diagram:"Диаграмма түрі",
        diagrGist: "Гистограмма",
        diagrGraph: "Кесте",
        diagrCircle: "Дөңгелек диаграмма",
        diagrline: "Штрих-диаграмма",
        diagrMap: "Карта",
        editBokMkbMedinfo: "Екінші буынға арналған нұсқаулық (ICD)",
        editBokDrMedinfo: "Екінші буынға арналған анықтамалық анықтама (басқалары)",
        yearsExprStrings: "Жылдар (мысал - 2017-2018,2021)",
        editPartsMedinfo: "Medinfo бөлімдерін өңдеу",
        part: "Бөлім",
        indicator: "Көрсеткіш",
        showWarningDiagram: "Егер сіз үш жылдан астам кезеңді таңдасаңыз, диаграммадағы деректер біріктірілуі мүмкін. Үш жылдан аз кезеңді таңдаған жөн.",
        openformtab: "Пішін мен кестені ашу",
        pokazateli: "Көрсеткіштер",
        kodstr: "Жол коды(басқару коды)",
        recalcItogs: "Қорытындыларды есептеңіз",
        edit: "Өңдеу",
        delete: "Жою",
        print: "Мөр",
        deletedData:"Деректер жойылды!",
        deleteDataForm: "Кесте деректерін жою",
        deleteDataForm2: "Пішін деректерін жою",
        deleteQuestion: "Кесте деректерін жою: %{table}, пішіндер:%{form}, нысан коды:%{kobj}?",
        deleteQuestion2: "Деректерді, пішіндерді жою: %{form}, нысан: %{obj}, жыл: %{year}, кезең: %{period}, кесу:%{raz}?",
        noDataForm: "Пішін деректері жоқ",
        close: "Жабық",
        accept: "Қабылдау",
        search: "Іздеу",
        exportToExcel: "Excel -ге экспорттау",
        object: "Объект",
        selectParams: "Параметр таңдау",
        selectedParams: "Таңдалған опциялар",
        load: "Жүктеу",
        hide: "Жасыру",
        yearClose: "Жылдық есепті өңдеу жылы",
        yearCloseMonth: "Айлық есепті өңдеу жылы",
        monthClose: "Ай сайынғы есепті өңдеу айы",
        showRequiredSave: "Деректер базасында бұл ұйымның деректері жоқ, деректерді сақтау керек",
        childOrgs: {
            title: "Ведомстволық бағынысты ұйымдар",
            parentOrg: "Бас ұйым",
            kodOrg: "Код",
            nameOrg: "Аты",
            del: "Бағыныштылықты жою",
            addChild: "Бала ұйымын қосыңыз",
            addSucces: "Еншілес компания қосылды!",
            removeSucces: "Жіберу жойылды!",
            addNoOrgFail: "Ұйым жоқ",
            warnOrgIsExistParent: "Бұл ұйымның бас ұйымы бар: %{kodOrg}",
            addOrgToOkpolu: "Добавить организацию в okpolu",
            childOrg: "Ведомстволық бағынысты ұйым",
            users: "Ведомстволық бағынысты ұйымдардың пайдаланушылары",
            users2: "Пайдаланушылар",
            editNameOrg: "Ұйымның атауын өзгерту",
        },
        forRaion: "Аудан бойынша",
        forSub: "ОКЖ және ведомстволық бағынысты ұйымдар бойынша",
        selectForms: "Пішіндерді таңдаңыз",
        selectMonths: "Айларды таңдаңыз",
        razSrcEqDest: "Бастапқы кесу соңғы кесуге тең болмауы керек",
        selectObjs: "Объектілерді таңдау",
        selectRazSrc: "Бастапқы кесулерді таңдаңыз",
        statusForm: {
            title: "Пішіндердің күйі (блоктау)",
            tit2: "Ұйымдар және форма статустары",
            titFormUncheck: "- Пішін тексерілмеген (ұйымның деректерін өзгертуге рұқсат етілген)",
            titFormCheck: "- Пішін тексерілді (ұйым деректерді өзгертуге тыйым салады)",
            titFormUncheckRk: "- Пішін расталмаған (төмен деңгейлердегі (аймақ/ұйым) деректерге өзгертулер рұқсат етіледі)",
            titFormCheckRk: "- Пішін тексерілді (төмен деңгейлерде (аймақ/ұйым) деректерді өзгертуге тыйым салынады))",
        },
        statusTable: {
            title: "Кесте күйі (құлыптаулы)"
        },
        checked: "Тексерілді",
        noChecked: "Тексерілмеген",
        infoData: {
            print: "Мөр",
            printExcel: "Excel файлын жасаңыз",
            title: "Деректердің қолжетімділігі күйі",
            period: "Период:",
            raz: "кесінді:",
            form: "Пішін:",
            obj: "Объект:",
            dt: "Жасалу күні мен уақыты:",
        },
        orgSubordinates: "Ведомстволық бағынысты ұйымдар",
        all: "Барлық",
        selectOrgDlg: {
            title: "Ұйым таңдаңыз",
            comment: "Ұйымдар тiзiмін қалыптастыру үшiн кодтың бiр бөлiгiн немесе ұйымның атын енгізіңіз"
        },
        packetDoc: {
            t1: "Лицензиалық келісімді тіркеу",
            t2: "«Мединформ» ЖШС-мен Лицензиалық келісім жасау үшін қажет»",
            t3: "Жүктеп алыңыз, толтырыңыз және қол қойыңыз",
            t4: "Лицензиалық келісім шарт",
            t5: "Жүктеп алу",
            t6: "Авторлық куәлік жүктеп алу",
            t7: "Жылдық Лицензианың құнын төлеңіз",
            t8: "Электрондық пошта арқылы жіберу",
            t9: "Электрондық пошта арқылы электрондық құжаттар жіберу",
            t10: "Қол қойылған Лицензиалық келісім шартының көшірмесі",
            t11: "Жылдық Лицензианың төленгенін растайтын құжат (төлем тапсырмасы, банктік түбіртек, интернет-банкинге үзіндісі)",
            t12: "Мемлекеттік сатып алу порталында шарт жасасу",
            t13: "Техникалық сипаттама",
            t14: "Коммерциалық ұсыныс",
            t15: "КТРУ 711211.000.000001 техникалық кеңес беру қызметтері",
            t16: "Портал \"Медстатқа\" кіру, жылдық Лицензианы төлегеннен кейін қамтамасыз етіледі",
            t17: "Байланыс телефоны",
            t18: "Порталға кіру",
            t19: "«Медстат», «Мединфо» порталында жұмыс істеу нұсқауларын жүктеп алыңыз",
            t150: "Номер объявления отправить на электронную почту",
            t151: "Хабарландырудың номерін",
            t152: "электрондық поштаға жіберу керек.",
        },
        editOrgs: {
            kod: "Код",
            nameRu: "Аты (орыс)",
            nameKz: "Аты (қаз.)",
            te: "Аумақтық бірлік (te)",
            obl: "Облыс (obl)",
            raion: "Аудан (raion)",
            nasel: "Елді мекен, аумақтық бірлік (te)",
            adr: "Мекенжай",
            isMedstat: "Ұйымдарға Медстатқа деректерді енгізуге рұқсат етілген",
            edit: "Өңдеу",
            loading: "Жүктелуде...",
            filt: "Сүзгі",
            kodName: "Код/Аты",
            notRaion: "Аудан белгіленбеген",
            clearFilter: "Сүзгіні тазалау",
        },
        accessRazd: {
            tit: "Кесу/фигуралар үшін енгізу рұқсаттары",
            titIsAccess: "Ұйым деректерді енгізуге арналған бөлімдер/формалар:",
            allRf: "Барлық",
            listRf: "Тізім",
            razrs: "Кеседі",
            forms: "Пішіндер",
            selRazs: "Кесулерді таңдаңыз",
            selForms: "Пішіндерді таңдаңыз",
            createList: "Есепті кезеңдегі қолда бар деректер негізінде пішіндер мен бөлімдердің тізімін жасаңыз:",
            btCopyFromYear: "Тізім жасау",
            isGod: "Жылдық есеп",
            isMes: "Ай сайынғы есеп"
        },
        noAccess: "Кіру жоқ",
        audit: {
            tit: "Пайдаланушы әрекеттерінің аудиті",
            startDate: "Кезеңнен бастап",
            endDate: "Авторы",
            action: "Пайдаланушы әрекеті",
            actionDelete: "Пішіндерді/кестелерді жою",
            logged: "күні",
            action: "Әрекет",
            message: "Хабар",
            user: "Пайдаланушы",
            ipAddress: "IP мекенжайы"
        },
        classMkb: "Класс МКБ-10",
        templateExcel: {
            title: "Шаблоны форм Excel",
            load: "Загрузить",
        },
        quartsComments: {
            q10: "= қаңтар+ақпан+наурыз",
            q20: "= сәуір+мамыр+маусым",
            q21: "= қаңтар+ақпан+наурыз+сәуір+мамыр+маусым",
            q30: "= шілде+тамыз+қыркүйек",
            q31: "= қаңтар+ақпан+наурыз+сәуір+мамыр+маусым+шілде+тамыз+қыркүйек",
            q40: "= қазан+қараша+желтоқсан",
            q4: "= қаңтар+ақпан+наурыз+сәуір+мамыр+маусым+шілде+тамыз+қыркүйек+қазан+қараша+желтоқсан",
            q40: "= 1 тоқсан + 2 тоқсан + 3 тоқсан + 4 тоқсан",
            q210: "= 1-тоқсан + 2-тоқсан",
            q310: "= 1-тоқсан + 2-тоқсан + 3-тоқсан",
            svodSrc: "Аралас:",
            error: "Қате: %{err}"
        },
        perSrcEqDest: "Бастапқы кезең нәтиже кезеңге тең болмауы керек",
        periodSrc: "Бастапқы кезең:",
        periodDest: "Қорытынды кезең:",
        notExistsDataSrcPer: "Бұл кезеңге қатысты дереккөзде деректер жоқ",
        pstrCntObj: "Нысандар %{cnt}",
        erMonthQuart: "Осы кезең/кезеңдер үшін айлар/тоқсандар бойынша деректер жоқ"
    }
};